<!-- añadir / eliminar zonas apds  -->

<template>
  <div class="apd_M_zonas">   
      <div>
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="$emit('onEvent', { accion:'cerrar' })">
            </base_Header>                  
          </div>


          <!-- Contenido -->
          <div class="contenedor" style="width:500px"> 
            <div class="columna">
              <div class="conflex">
                <v-btn 
                  v-bind="$cfg.btra_cfg.standard"
                  title="Guardar datos"
                  @click="guardar()">
                    <v-icon>{{'mdi-content-save'}}</v-icon>
                </v-btn>

                <v-btn 
                  v-if="cps"
                  v-bind="$cfg.btra_cfg.standard"
                  style="margin-left:10px"
                  title="Pegar"               
                  @click="pegar()">
                    <v-icon >{{'mdi-content-paste'}}</v-icon>
                </v-btn>
              </div>
              
              <!-- <div>Introduce el código postal o parte del mismo<br>separado por comas</div> -->
              <v-textarea
                v-bind="$textarea"
                style="padding-top:10px"
                v-model="schema.ctrls.txt.value"
                :label="schema.ctrls.txt.label"
                rows="3"
                no-resize>
              </v-textarea>

            </div>
          </div>
        </v-sheet> 
      </div>
  </div>
</template>



<script>

  import { mixinCtrls } from "@/mixins/mixinCtrls.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
 
  export default {
    mixins: [mixinCtrls],
    components: { base_Header },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:Number, default: 0},
      accionRow: { type:Object, default: null},  
      tipo: { type:Number, default: 0},
      cps: { type:String, default: ""},
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {          
            txt: { f:'txt', label:'Introduce el código postal o parte del mismo separado por comas', value:""}
          }
        },
        api:'',
        stName:'stMapd_M_zonas',      
      };
    },


    created() {
      this.ini_data();
      this.ini_schemaCtrls();
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = this.accion==1? 'AÑADIR' : 'ELIMINAR';        
      },


      //
      ini_schemaCtrls() {
      },


      //
      async guardar() {
        if (this.schema.ctrls.txt.value== "") {
          this.$root.$alert.open('Debe introducir uno o varios códigos postales', 'error');
          return;
        }
        
        let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'codpostales',
          fnt:this.accion==1? 'set' : 'anular',
          apd:this.accionRow.id,
          cpo:this.schema.ctrls.txt.value,
          tp:this.tipo
        }};
       console.log('argumentos de guardar: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args }); 
        console.log('apiResult de guardar: ', apiResult);       
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Operación realizada correctamente', 'success', 1800);

        // actualizo lista de zonas y salgo
        this.$emit('update_zonas', { r:apiResult.r, tipo:this.tipo });
        this.$emit('onEvent', { accion:'cerrar' });
      },


      pegar() {        
        this.schema.ctrls.txt.value= this.cps;
      }


    }
  };
</script>
